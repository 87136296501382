import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BackgroundImage } from "components/Shared/BackgroundImage"
import { IPageTemplateProps } from "./Page"
import { PageTitle } from "components/Shared/PageTitle"
import styled from "styled-components"
import { PageWrapper } from "components/Shared/PageWrapper"
import Card from "components/Shared/Card"
import { StyledContainer } from "components/Shared/Container"
import { WrapperCards } from "components/Shared/WrapperCards"
import SEO from "components/Seo"

interface ICategoryProps {
  allWpCategory: {
    edges: [
      {
        node: {
          link: string
          name: string
          id: string
          nodeType: string
          slug: string
          categoryImage: {
            categoryImage: {
              sourceUrl: string
            }
            order: number
          }
        }
      }
    ]
  }
}

const Wrapper = styled(PageWrapper)`
  z-index: 1;
  position: relative;
`

const BlogsTemplate: FC<IPageTemplateProps> = ({ pageContext }) => {
  const { allWpCategory }: ICategoryProps = useStaticQuery(graphql`
    query MyCategoriesQuery {
      allWpCategory {
        edges {
          node {
            link
            name
            id
            nodeType
            slug
            categoryImage {
              categoryImage {
                sourceUrl
              }
              order
            }
          }
        }
      }
    }
  `)

  if (!allWpCategory) return null

  return (
    <>
      <SEO
        title={pageContext.seo ? pageContext.seo.title : pageContext.title}
        description={pageContext.seo ? pageContext.seo.metaDesc : null}
        image={
          pageContext.seo &&
          pageContext.seo.opengraphImage &&
          pageContext.seo.opengraphImage.sourceUrl
            ? pageContext.seo.opengraphImage.sourceUrl
            : null
        }
      />
      <BackgroundImage
        style={{
          backgroundImage: `url(${
            pageContext.featuredImage
              ? pageContext.featuredImage.node.sourceUrl
              : null
          })`,
        }}
      />
      <Wrapper className="content-wrapper">
        <PageTitle>{pageContext.title}</PageTitle>
        <StyledContainer>
          <WrapperCards>
            {allWpCategory.edges
              .sort(
                (a, b) =>
                  a.node.categoryImage.order - b.node.categoryImage.order
              )
              .map(category => {
                const { node } = category
                if (node.slug !== "uncategorized") {
                  return (
                    <Card
                      key={node.id}
                      content={{
                        link: node.link,
                        title: node.name,
                        imgSrc:
                          node.categoryImage && node.categoryImage.categoryImage
                            ? node.categoryImage.categoryImage.sourceUrl
                            : null,
                        type: node.nodeType,
                      }}
                    />
                  )
                }
              })}
          </WrapperCards>
        </StyledContainer>
      </Wrapper>
    </>
  )
}

export default BlogsTemplate
